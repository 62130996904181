/**
 * @string public name 
 * @string public translate optionnal
 * @string public unit optionnal
 * @string public force_value optionnal
 * 
 * Exemple = [
 *  {name: "width", translate:"", unit: "px", force_value: "100"}, 
 *  {name: "height", translate:"min-height", unit: "px", force_value: ""}, 
 *  {name: "framborder", translate:"border", unit: "px", force_value: ""}
 * ]
 * */
class AttrDef {
    constructor(
        name,
        translate = "",
        unit = "",
        force_value = ""
    ) {
        this.name = name;
        this.translate = translate;
        this.unit = unit;
        this.force_value = force_value;
    }
}

/**
 * @object private element Iframe 
 * */
class IframPatch {

    #element = null;

    constructor(element) {
        this.#element = element;
    }

    getIframe() {
        return jQuery(this.#element);
    }

    /**
     * @array[AttrDef] listAttrDef : 
     * */
    transformAttrToStyle(listAttrDef = []) {

        const styleDatas = {};

        if (listAttrDef.length > 0) {

            listAttrDef.forEach(
                attr => {

                    const attrValue = this.getIframe().attr(attr.name);
                    styleDatas[((attr.translate) ? attr.translate : attr.name)] = ((attr.force_value) ? attr.force_value : attrValue) + ((attr.unit) ? attr.unit : "");
                    this.getIframe().removeAttr(attr.name);

                }
            );

        }

        this.getIframe().css(styleDatas);

    }
}

/**
 * @array[iFrame] private list Iframs optionnal 
 * @array[AttrDef] private list AttrDef optionnal 
 * */
class IframPatchCollection {

    #listIframs = [];
    #listAttrDef = [];

    constructor(listIframs = [], listAttrDef = []) {
        this.#listIframs = listIframs;
        this.#listAttrDef = listAttrDef;
        this.#init();
    }

    #init() {

        if (this.#listIframs.length > 0 && this.#listAttrDef.length > 0) {

            this.#listIframs.forEach(
                ifram => {
                    const iframPatch = new IframPatch(ifram);
                    iframPatch.transformAttrToStyle(this.#listAttrDef);
                }
            );
        }

    }
}

export { IframPatchCollection, AttrDef }; 